<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2025-03-04 16:09 -->
<!-- Description:  -->
<template>
  <div>
    <section class="my-2">

    </section>
    <section class="my-2">
      <button @click="$refs.elIframe.contentWindow.print();" class="btn btn-outline-secondary mr-2"><i
          class="fas fa-print"></i>
        Imprimir</button>

      <select style="width: 150px;" class="custom-select" v-model="op_dates" @change="setDates(); reload();" name=""
        id="">
        <option value=""></option>
        <option value="A">Este Mes</option>
        <option value="B">Mes Pasado</option>
      </select>
      Inicio: <input id="" type="date" name="" v-model="ini_date" @change="reload()">
      Fin:<input id="" type="date" name="" v-model="end_date" @change="reload()">

    </section>
    <iframe ref="elIframe" style="height: 1080px;" class="w-100" :src="link" frameborder="0"></iframe>
  </div>
</template>
<script>
import http from "src/utils/http.js";
// script content
export default {
  name: "Monitor",
  components: {
    //
  },

  // directives

  props: {
    //
  },

  data: () => ({
    ini_date: "",
    end_date: "",
    link: "",
    op_dates: "A",
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    this.setDates();

    this.reload();
  },

  methods: {
    setDates() {
      if (this.op_dates == "A") {
        const today = new Date();
        const lastMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfLastMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        this.ini_date = lastMonth.toISOString().split('T')[0];
        this.end_date = endOfLastMonth.toISOString().split('T')[0];
      } else if (this.op_dates == "B") {
        const today = new Date();
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

        this.ini_date = lastMonth.toISOString().split('T')[0];
        this.end_date = endOfLastMonth.toISOString().split('T')[0];
      }
    },
    reload() {
      if (this.ini_date && this.end_date) {
        http.get("api/reports/dental/monitor", {
          params: {
            ini_date: this.ini_date,
            end_date: this.end_date,
          }
        })
          .then(response => {
            const blob = new Blob([response.data], {type: 'text/html'});
            this.link = URL.createObjectURL(blob);
          })
          .catch(() => {
            // console.error("There was an error fetching the page:", error);
          });
      }

    }
    //
    //
  }
};
</script>
<style scoped></style>
